import {Component} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, MinLengthValidator, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  form: FormGroup;
  warranty = true;
  files = [];
  pending = false;
  success = false;
  error = false;
  symbol: '';
  formHasError = false;

  fileQuantityError = false;
  fileSizeError = false;

  currentDate = new Date();

  maxDate = new Date().toJSON().split('T')[0];
  tmpMinDate = new Date().setFullYear(this.currentDate.getFullYear() - 5);
  minDate = new Date(this.tmpMinDate).toJSON().split('T')[0];

  bankAccountValidator(formControl: AbstractControl): ValidationErrors {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('legalBasisName').value === 'odpowiedzialność za niezgodność towaru z umową' && formControl.parent.get('expectation').value === 'odstąpienie od umowy') {
      return Validators.required(formControl);
    }
    return null;
  }


  onFileChange(event) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size < 16000000) {
          if (this.files.length < 5) {
            this.files.push(event.target.files[i]);
          } else {
            this.fileQuantityErrorChecker();
          }
        } else {
          this.fileSizeErrorChecker();
        }
      }
    }
  }

  deleteFile(number) {
    const newFiles = [];
    for (let i = 0; i < this.files.length; i++) {
      if (i !== number) {
        newFiles.push(this.files[i]);
      }
    }
    this.files = newFiles;
  }

  fileQuantityErrorChecker() {
    this.fileQuantityError = true;
    setTimeout(() => {
      this.fileQuantityError = false;
    }, 5000);
  }

  fileSizeErrorChecker() {
    this.fileSizeError = true;
    setTimeout(() => {
      this.fileSizeError = false;
    }, 5000);
  }

  constructor(private http: HttpClient) {

    this.form = new FormGroup({
      clientName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      clientAddressStreet: new FormControl('', [Validators.required, Validators.minLength(3)]),
      clientAddressZipCode: new FormControl('', [Validators.required, Validators.minLength(5)]),
      clientAddressCity: new FormControl('', [Validators.required, Validators.minLength(3)]),
      clientPhoneNumber: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(11), Validators.pattern(/^\d+$/)]),
      clientEmail: new FormControl('', [Validators.required, Validators.email]),

      clientBankAccountNumber: new FormControl('', [this.bankAccountValidator, Validators.pattern('^[0-9]{26}$')]),

      otherDeliveryAddress: new FormControl(false),
      deliveryName: new FormControl(''),
      deliveryAddressStreet: new FormControl(''),
      deliveryAddressZipCode: new FormControl(''),
      deliveryAddressCity: new FormControl(''),
      deliveryPhoneNumber: new FormControl(''),

      legalBasisName: new FormControl('', [Validators.required]),
      orderNumber: new FormControl('', [Validators.required, Validators.minLength(5)]),
      expectation: new FormControl('naprawa', []),
      dateOfFailure: new FormControl('', [Validators.required, Validators.minLength(5)]),
      failureDescription: new FormControl('', [Validators.required, Validators.minLength(10)]),
      productName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      dateOfPurchase: new FormControl('', [Validators.required, Validators.minLength(5)])
    });

    this.form.get('legalBasisName').valueChanges
      .subscribe(value => {
        this.form.get('clientBankAccountNumber').setValue('');
        this.form.get('clientBankAccountNumber').updateValueAndValidity();
      });

    this.form.get('expectation').valueChanges
      .subscribe(value => {
        this.form.get('clientBankAccountNumber').setValue('');
        this.form.get('clientBankAccountNumber').updateValueAndValidity();
      });
  }

  send() {

    const formData = new FormData();
    for (let j = 0; j < this.files.length; j++) {
      formData.append('files', this.files[j]);
    }
    formData.append('body', JSON.stringify(this.form.value));

    console.log(this.form.value);

    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form.valid) {
      this.pending = true;
      this.http.post('https://ecapi.windydev.pl/client/warranties/econova', formData).subscribe(res => {
        if (res !== null) {
          // @ts-ignore
          this.symbol = res.symbol;
          this.success = true;
          this.error = false;
          this.pending = false;
        } else {
          this.success = false;
          this.error = true;
          this.pending = false;
        }
      }, error1 => {
        this.success = false;
        this.error = true;
        this.pending = false;
      });
    } else {
      this.formHasError = true;
    }

    console.log(this.form.get('clientBankAccountNumber').status);

  }
}

